// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const search_container = document.querySelector(".search-block.-flyout");
const overlay          = document.querySelector(".search_background");
const toggle           = document.querySelector("[data-panel=mobile-search]");

const focus_trapper = focusTrap(".search-block");

const toggle_search = () => {
    search_container.classList.toggle("is-active");

    if (search_container.classList.contains("is-active")) {
        focus_trapper.activate();
        search_container.setAttribute("aria-hidden", "false");
        search_container.querySelector(".search-form_input").focus();
    } else {
        focus_trapper.deactivate();
        search_container.setAttribute("aria-hidden", "true");
        toggle.focus();
    }
};

toggle.addEventListener("click", () => {
    toggle_search();
});

overlay.addEventListener("click", () => {
    toggle_search();
});
